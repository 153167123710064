import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import moment from 'moment'

import fullscreen from 'vue-fullscreen'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'https://set.1lsq.cn/admin'
} else {
  axios.defaults.baseURL = 'https://set.1lsq.cn/admin'
}

axios.defaults.timeout = 22500
axios.defaults.headers = {
  'Content-Type': 'application/json'
}
// 添加请求拦截器
axios.interceptors.request.use((config) => {
  // 判断本地是否包含token和用户m_id (排除登录页面)
  if (config.url !== '/base' && config.url !== '/login') {
    if (store.state.token && store.state.userInfo.m_id) {
      // 在请求头上添加token
      if (store.state.token) {
        config.headers.Authorization = store.state.token
      }
      return config
    } else {
      ElementUI.Message.error('您还未登录，请先登录')
      router.push('/')
    }
  } else {
    return config
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  if (response.data.code === 4022) {
    ElementUI.Message.error(response.data.msg)
    router.push('/')
  }
  // 判断响应头中是否含有token
  if (typeof response.headers.authorization !== 'undefined') {
    store.commit('updateToken', response.headers.authorization)
  }
  return response
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  Promise.reject(error).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
    } else if (error.request) {
    } else {
      // Something happened in setting up the request that triggered an Error
    }
  })
  return Promise.reject(error)
})

Vue.use(ElementUI, { size: 'small', zIndex: 3000 })
Vue.use(fullscreen)
Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.$moment = moment
Vue.use(VueAwesomeSwiper, {})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
