<template>
  <el-container class="admin-page">
    <common-aside></common-aside>
    <el-container class="page-main">
      <common-header></common-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <common-footer></common-footer>
    </el-container>
  </el-container>
</template>

<script>
import CommonHeader from '../components/common/CommonHeader'
import CommonFooter from '../components/common/CommonFooter'
import CommonAside from '../components/common/CommonAside'
export default {
  name: 'Home',
  components: {
    CommonHeader,
    CommonFooter,
    CommonAside
  }
}
</script>

<style scoped>
.admin-page{
  height: 100vh;
}
.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
}
.page-main{
  display: flex;
  flex-direction: column;
}
</style>
