<template>
  <div class="common-header">
    <el-header>
      <div class="left">
        <div class="left-item">
          <el-switch v-model="menuStatus" active-color="#13ce66" inactive-color="#ff4949" :active-value="false" :inactive-value="true" @change="menuStatusChange"></el-switch>
        </div>
        <div class="left-item full-screen" @click="toggleScreen">
          <i class="el-icon-full-screen"></i>
        </div>
      </div>
      <div class="right">
<!--        <div class="right-item">-->
<!--          <i class="el-icon-message"></i>-->
<!--        </div>-->
        <div class="right-item">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <el-avatar :src="this.$store.state.userInfo.avatar"></el-avatar>
              <span>{{this.$store.state.userInfo.nick}}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="clear">清除缓存</el-dropdown-item>
              <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </el-header>
  </div>
</template>

<script>
export default {
  name: 'CommonHeader',
  data () {
    return {
      menuStatus: false,
      // 全屏按钮
      fullscreen: false
    }
  },
  methods: {
    menuStatusChange (newVal) {
      this.$store.commit('updataMenuCollapse', newVal)
    },
    // 全屏功能
    toggleScreen () {
      this.$fullscreen.toggle(this.$el.querySelector('.example'), {
        wrap: false,
        callback: this.fullscreenChange
      })
    },
    fullscreenChange (fullscreen) {
      this.fullscreen = fullscreen
    },
    async handleCommand (command) {
      if (command === 'logout') {
        this.$store.commit('clearUserInfo')
        this.$store.commit('clearToken')
        this.$message.success('退出登录成功！')
        await this.$router.push('/')
      } else if (command === 'clear') {
        const { data: res } = await this.$http.post('/clear-runtime')
        if (res.code === 200) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      }
    }
  }
}
</script>

<style scoped>
.el-header{
  background-color: #6777ef;
  color: #FFFFFF;
  text-align: left;
  line-height: 60px;
  transition: all .5s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -moz-user-select:none; /*火狐*/
  -webkit-user-select:none; /*webkit浏览器*/
  -ms-user-select:none; /*IE10*/
  -khtml-user-select:none; /*早期浏览器*/
  user-select:none;
}
.left{
  display: flex;
  align-items: center;
}
.left-item{
  margin-right: 30px;
  height: 60px;
}
.full-screen{
  cursor: pointer;
}
.right{
  display: flex;
  align-items: center;
}
.right .right-item{
  margin-left: 30px;
  height: 60px;
  cursor: pointer;
}
.right .el-dropdown-link{
  display: flex;
  align-items: center;
}
.right .el-dropdown-link span{
  margin-left: 10px;
  color: #FFFFFF;
}
.right .el-dropdown-link i{
  color: #FFFFFF;
}
</style>
