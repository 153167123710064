<template>
  <div class="common-aside">
    <el-aside width="auto">
      <div class="logo" v-if="!$store.state.menuCollapse">
        <img src="../../assets/img/logo-admin.png"/>
      </div>
      <div class="menu-content" ref="menuRef">
        <div class="menu-body">
          <el-menu :default-active="activePath" :router="true" class="el-menu-ul" :collapse="$store.state.menuCollapse" :collapse-transition="false">
            <template :index="item.id" v-for="item in menuList">
              <el-menu-item :index="item.v1" :key="item.id" v-if="item.children.length === 0" @click="saveNavState(item.id)">
                <i :class="item.icon"></i>
                <span>{{ item.v0}}</span>
              </el-menu-item>
              <el-submenu :key="item.id" v-else :index="item.id.toString()">
                <!-- 一级菜单的模板区域 -->
                <template slot="title">
                  <i :class="item.icon"></i>
                  <span>{{ item.v0}}</span>
                </template>
                <!-- 二级菜单 -->
                <el-menu-item :index="subItem.v1" v-for="subItem in item.children" :key="subItem.id" @click="saveNavState(subItem.id)">
                  <!-- 导航开启路由模式：
                    将index值作为导航路由 -->
                  <!-- 二级菜单的模板区域 -->
                  <template slot="title">
                    <i :class="subItem.icon"></i>
                    <span>{{ subItem.v0}}</span>
                  </template>
                </el-menu-item>
              </el-submenu>
            </template>
          </el-menu>
        </div>
      </div>
    </el-aside>
  </div>
</template>

<script>
import BScroll from '@better-scroll/core'
import ScrollBar from '@better-scroll/scroll-bar'
import MouseWheel from '@better-scroll/mouse-wheel'
import ObserveDOM from '@better-scroll/observe-dom'
BScroll.use(ScrollBar)
BScroll.use(MouseWheel)
BScroll.use(ObserveDOM)
export default {
  name: 'CommonAside',
  data () {
    return {
      menuList: [],
      activePath: '0'
    }
  },
  created () {
    this.getMenus()
    this.activePath = String(window.sessionStorage.getItem('activePath'))
  },
  mounted () {
    this.initBscroll()
  },
  methods: {
    // 保存连接的激活地址
    saveNavState (activePath) {
      window.sessionStorage.setItem('activePath', String(activePath))
    },
    async getMenus () {
      const { data: res } = await this.$http.get('/menus')
      if (res.code === 200) {
        this.menuList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    initBscroll () {
      this.$nextTick(() => {
        this.scroll = new BScroll(this.$refs.menuRef, {
          observeDOM: true,
          probeType: 3,
          scrollY: true,
          scrollX: false,
          click: true,
          mouseWheel: true,
          scrollbar: {
            customElements: [this.$refs.horizontal],
            fade: true,
            interactive: true,
            scrollbarTrackClickable: true
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.el-aside {
  background-color: #6777ef;
  color: #333;
  text-align: left;
  line-height: 200px;
  height: 100%;
}
.menu-content{
  position: relative;
  height: calc(100% - 100px);
  overflow: hidden;
}
/deep/.el-menu{
  border-right: 0;
  background-color: #6777ef;
}
.el-submenu /deep/.el-menu,.el-menu--inline{
  background-color: #404db1;
}
.el-menu-item{
  color: #FFFFFF;
}
.el-menu-item:hover{
  color: #6777ef;
}
.el-menu-item i{
  color: #FFFFFF;
}
.el-menu-item:hover i{
  color: #6777ef;
}
/deep/.el-submenu__title{
  color: #FFFFFF;
}
/deep/.el-submenu__title:hover{
  color: #6777ef;
}
/deep/.el-submenu__title i{
  color: #FFFFFF;
}
/deep/.el-submenu__title:hover i{
  color: #6777ef;
}
/deep/.el-menu-item.is-active{
  color: #6777ef;
  background-color: #f8fafb;
}
.el-menu-item-group{
  background-color: rgba(103,119,239,0.7);
}
.el-menu-item-group{
  padding: 0;
}
.logo{
  width: 100%;
  line-height: 1;
  height: 60px;
}
.logo img{
  width: 100%;
  height: 100%;
}
</style>
