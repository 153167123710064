import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Login', component: Login },
  {
    path: '/admin',
    component: Home,
    children: [
      { path: 'dashboard', component: () => import('./../components/dashboard/Dashboard.vue') },
      { path: 'setup/site', component: () => import('./../components/setup/Site.vue') },
      { path: 'setup/bucket', component: () => import('./../components/setup/Bucket.vue') },
      { path: 'setup/bucket/add', component: () => import('./../components/setup/BucketAdd.vue') },
      { path: 'setup/banner', component: () => import('./../components/setup/Banner.vue') },
      { path: 'account/agent', component: () => import('./../components/account/Agent.vue') },
      { path: 'account/role', component: () => import('./../components/account/Role.vue') },
      { path: 'account/auth', component: () => import('./../components/account/Auth.vue') },
      { path: 'content/campus', component: () => import('./../components/content/Campus.vue') },
      { path: 'content/campus/add', component: () => import('./../components/content/CampusAdd.vue') },
      { path: 'content/campus/edit/:mid', component: () => import('./../components/content/CampusEdit.vue') },
      { path: 'content/courses', component: () => import('./../components/content/Courses.vue') },
      { path: 'content/courses/special', component: () => import('./../components/content/courses/Special.vue') },
      { path: 'content/courses/special/add', component: () => import('./../components/content/courses/SpecialAdd.vue') },
      { path: 'content/courses/special/edit/:mid', component: () => import('./../components/content/courses/SpecialEdit.vue') },
      { path: 'content/courses/classify', component: () => import('./../components/content/courses/Classify.vue') },
      { path: 'content/courses/classify/add', component: () => import('./../components/content/courses/ClassifyAdd.vue') },
      { path: 'content/courses/classify/edit/:mid', component: () => import('./../components/content/courses/ClassifyEdit.vue') },
      { path: 'content/courses/min-classify', component: () => import('./../components/content/courses/MinClassify.vue') },
      { path: 'content/courses/min-classify/add', component: () => import('./../components/content/courses/MinClassifyAdd.vue') },
      { path: 'content/courses/min-classify/edit/:mid', component: () => import('./../components/content/courses/MinClassifyEdit.vue') },
      { path: 'content/courses/course', component: () => import('./../components/content/courses/Course.vue') },
      { path: 'content/courses/course/add', component: () => import('./../components/content/courses/CourseAdd.vue') },
      { path: 'content/courses/course/edit/:mid', component: () => import('./../components/content/courses/CourseEdit.vue') },
      { path: 'content/courses/subject', component: () => import('./../components/content/courses/Subject.vue') },
      { path: 'content/courses/subject/add', component: () => import('./../components/content/courses/SubjectAdd.vue') },
      { path: 'content/courses/subject/edit/:mid', component: () => import('./../components/content/courses/SubjectEdit.vue') },
      { path: 'teacher', component: () => import('./../components/teacher/Teacher.vue') },
      { path: 'order/index', component: () => import('./../components/order/Index.vue') }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
