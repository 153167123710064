import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseInfo: {
      copyright: '',
      support: ''
    },
    userInfo: {
      m_id: '',
      nick: '',
      avatar: '',
      role: 1
    },
    token: '',
    menuCollapse: false
  },
  mutations: {
    updateBaseInfo (state, baseinfo) {
      state.baseInfo.copyright = baseinfo.copyright
      state.baseInfo.support = baseinfo.support
    },
    updataUserInfo (state, userinfo) {
      state.userInfo.m_id = userinfo.m_id
      state.userInfo.nick = userinfo.nick
      state.userInfo.avatar = userinfo.avatar
      state.userInfo.role = userinfo.role
    },
    clearUserInfo (state, userinfo) {
      state.userInfo.m_id = ''
      state.userInfo.nick = ''
      state.userInfo.avatar = ''
      state.userInfo.role = 1
    },
    updateToken (state, token) {
      state.token = token
    },
    clearToken (state, token) {
      state.token = ''
    },
    updataMenuCollapse (state, status) {
      state.menuCollapse = status
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.sessionStorage,
      reducer (val) {
        return {
          // 只储存state中的assessmentData
          userInfo: val.userInfo,
          token: val.token,
          menuCollapse: val.menuCollapse,
          baseInfo: val.baseInfo
        }
      }
    })
  ]
})
