<template>
  <div class="common-footer">
    <el-footer>{{this.$store.state.baseInfo.support}}</el-footer>
  </div>
</template>

<script>
export default {
  name: 'CommonFooter'
}
</script>

<style scoped>
.el-footer {
  background-color: #e3eaef;
  color: #6777ef;
  text-align: center;
  line-height: 60px;
  transition: all .5s;
  font-size: 14px;
}
</style>
