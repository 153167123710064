<template>
  <div class="login-page">
    <div class="login-panel">
      <div class="left">
        <img src="../assets/img/a1.png"/>
      </div>
      <div class="right">
        <div class="logo-img">
          <img src="../assets/img/logo.jpg"/>
        </div>
        <el-form :model="loginForm" :rules="loginRules" ref="loginRef" class="login-form" :hide-required-asterisk="true">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="loginForm.username" clearable  prefix-icon="el-icon-user">
            </el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="loginForm.password" clearable show-password prefix-icon="el-icon-lock"></el-input>
          </el-form-item>
          <el-form-item style="margin-top: 40px; text-align: right;">
            <el-button type="primary" @click="submitForm">登 录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer">
      <div class="support">{{this.$store.state.baseInfo.support}}</div>
      <div class="record" @click="toRecord">{{this.$store.state.baseInfo.copyright}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 12, message: '长度在 3 到 12 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getBaseInfo()
  },
  methods: {
    async getBaseInfo () {
      const { data: res } = await this.$http.get('/base')
      if (res.code === 200) {
        this.$store.commit('updateBaseInfo', res.data)
      } else {
        this.$message.error(res.msg)
      }
    },
    submitForm () {
      this.$refs.loginRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/login', this.loginForm)
          if (res.code === 200) {
            // 更新vuex数据
            this.$store.commit('updataUserInfo', res.data)
            this.$message.success(res.msg)
            await this.$router.push('/admin/dashboard')
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    toRecord () {
      window.open('https://beian.miit.gov.cn/', '_blank')
    }
  }
}
</script>

<style scoped>
  .login-page{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/img/bg.jpg');
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .login-panel{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .left{}
  .right{
    position: relative;
    margin-left: 200px;
    background-color: rgba(255,255,255,0.8);
    box-shadow: 0 15px 35px rgb(0 0 0 / 10%), 0 3px 10px rgb(0 0 0 / 7%);
    padding: 20px;
  }
  .right .login-form{
    width: 300px;
    margin: 30px;
  }
  .right .logo-img{
    width: 360px;
  }
  .right .logo-img img{
    width: 100%;
  }
  .login-page .footer{
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-page .footer .support{
    font-size: 13px;
    font-weight: bold;
    margin-right: 10px;
  }
  .login-page .footer .record{
    font-size: 13px;
    font-weight: bold;
    color: #409EFF;
    cursor: pointer;
  }
</style>
